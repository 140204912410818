import { challengesListDataProviderPropsMap } from '../ChallengesListDataProvider/challengesListDataProviderPropsMap';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { getInstance } from '../../services/instance';
import {
  getChallengeSlugFromLocation,
  getUrlParams,
} from '../Location/locationProviderPropsMap';
import { isAvailableForJoinImmediately } from '../../selectors/challenges';
import {
  handleUserLogin,
  IChallengeDataProps,
  requestChallenge,
  requestChallengeSections,
  requestChallengeSteps,
  shouldReportErrorMonitor,
} from './helpers';
import { isProgramSlugValid } from '../../selectors/isProgramSlugValid';

import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { ChallengeServerlessData } from '../../api/challenges/challenge.api';

import memoize from 'lodash/memoize';
import { isMA } from '../../selectors/isMA';

export const challengeDataProviderPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IChallengeDataProps> {
  const slug = getChallengeSlugFromLocation(flowAPI);
  const { isEditor, isPreview } = flowAPI.environment;

  const isMyWalletInstalled =
    await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      sectionId: 'my_wallet',
      appDefinitionId: '4aebd0cb-fbdb-4da7-b5d1-d05660a30172',
    });

  let challengeResponse: ChallengeServerlessData = {
    challenge: null,
    badgesData: null,
    groupData: null,
  };

  const instanceFromUrl = getInstance(flowAPI);

  await handleUserLogin(flowAPI);
  try {
    if (!isEditor && slug && !isMA(flowAPI)) {
      challengeResponse = await requestChallenge(slug, flowAPI);

      if (!isProgramSlugValid(challengeResponse, slug)) {
        return;
      }

      flowAPI.bi?.updateDefaults({
        challengeId: challengeResponse?.challenge?.id,
      });
    }
  } catch (error) {
    if (!flowAPI.environment.isPreview && !flowAPI.environment.isEditor) {
      if (error.httpStatus !== 403) {
        handleError({
          error,
          context: 'getOwnerChallenge',
          preventErrorMonitorReport: !shouldReportErrorMonitor(error),
        });
      }
    }
  }

  if (isEditor || (!challengeResponse.challenge && isPreview)) {
    const { challengesListData } = await challengesListDataProviderPropsMap(
      flowAPI,
    );
    const chId =
      challengesListData?.memberChallenges[0]?.challenge?.settings?.seo?.slug ||
      challengesListData?.memberChallenges[0]?.challenge?.id;

    if (!isMA(flowAPI)) {
      challengeResponse = await requestChallenge(chId, flowAPI);
    }
  }

  const urlParams = getUrlParams(flowAPI);

  return {
    isDescriptionShown: urlParams.navigationType === 'description',
    challengeData: {
      ...challengeResponse,
      ...{
        isAvailableForJoinImmediately: isAvailableForJoinImmediately(
          challengeResponse.challenge,
        ),
      },
    },
    requestChallengeSections: async (idOrSlug: string): Promise<void> => {
      const provider = await challengeDataProviderPropsMap(flowAPI);
      const sections = await requestChallengeSections(idOrSlug, flowAPI, {
        Authorization: instanceFromUrl,
      });

      provider.challengeSections = sections;

      flowAPI.controllerConfig.setProps({
        challengeSections: sections,
      });
    },
    requestChallengeSteps: async (idOrSlug: string): Promise<void> => {
      const provider = await challengeDataProviderPropsMap(flowAPI);
      const steps = await requestChallengeSteps(idOrSlug, flowAPI, {
        Authorization: instanceFromUrl,
      });

      provider.challengeSteps = steps;

      flowAPI.controllerConfig.setProps({
        challengeSteps: steps,
      });
    },
    isMyWalletInstalled,
  };
});

export async function getChallengeId(
  flowAPI: ControllerFlowAPI,
): Promise<string> {
  try {
    const provider = await challengeDataProviderPropsMap(flowAPI);

    if (isMA(flowAPI)) {
      return undefined;
    }

    return provider?.challengeData?.challenge?.id;
  } catch (error) {
    handleError({ error, context: 'get challenge id' });
  }
}
